import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';

import {
  failureGetCardTransactions,
  failureGetTransactions,
  requestGetTransactions,
  successGetCardTransactions,
  successGetTransactions
} from './reducer';

import { getApiClient } from '@app/utils/apiUtils';
import { AnyAction } from '@reduxjs/toolkit';

export function* getTransactions(action: AnyAction): Generator<any, any, any> {
  try {
    const { date } = action.payload;
    const { page } = action.payload;

    const apiRes = yield call(
      [getApiClient(), 'get'],
      '/fetch-transactions' + (date !== '' ? `?date=${moment(date).format('YYYY-MM-DD')}` : '') + `&page=${page}`,
      {}
    );

    yield put(successGetTransactions(apiRes?.data ? apiRes?.data?.data : []));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetTransactions(err));
  }
}

export function* getCardTransactions(): Generator<any, any, any> {
  try {
    const apiRes = yield call([getApiClient(), 'get'], '/app-Transactions', {});

    yield put(successGetCardTransactions(apiRes?.data?.data ? apiRes?.data?.data : {}));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetCardTransactions(err));
  }
}

// Individual exports for testing
export default function* transactionsContainerSaga() {
  yield takeLatest(requestGetTransactions.toString(), getTransactions);
}

export const transactionsContainerSagaArr = [takeLatest(requestGetTransactions.toString(), getTransactions)];
