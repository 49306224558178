import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import {
  failureGetConfig,
  failureGetLeague,
  failureUpdateConfig,
  failureUpdateLeague,
  requestGetConfig,
  requestGetLeague,
  requestUpdateConfig,
  requestUpdateLeague,
  setUpdateKey,
  successGetConfig,
  successGetLeague,
  successUpdateConfig,
  successUpdateLeague
} from './reducer';

import { getApiClient } from '@app/utils/apiUtils';
import { AnyAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { translationMessages } from '@app/i18n';

export function* getConfig(): Generator<any, any, any> {
  try {
    const apiRes = yield call([getApiClient(), 'get'], '/config', {});

    yield put(successGetConfig(apiRes?.data?.data ? apiRes?.data?.data : {}));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetConfig(err));
  }
}

export function* updateConfig(action: AnyAction): Generator<any, any, any> {
  try {
    const config = action.payload.data;
    const updateKey = action.payload.updateKey;
    const mfaCode = action.payload.mfaCode;

    const user = yield call([Auth, 'currentAuthenticatedUser']);

    yield put(setUpdateKey(updateKey));

    const apiRes = yield call(
      [getApiClient(), 'put'],
      '/config',
      {
        mfaCode,
        configData: config
      },
      {}
    );

    const successMessages = action.payload.successMessages;

    if (apiRes.ok) {
      successMessages.forEach((messageString: string) => {
        message.success(messageString);
      });

      yield put(successUpdateConfig(action.payload.data));
    } else {
      if (apiRes.data.error === 'INVALID_MFA') {
        message.error('Invalid mfa code entered');
      } else {
        message.error('Something went wrong');
      }
      yield put(failureUpdateConfig('Something went wrong'));
    }
  } catch (err: any) {
    message.error(err.message);

    yield put(failureUpdateConfig(err));
  }
}

export function* getLeague(): Generator<any, any, any> {
  try {
    const apiRes = yield call([getApiClient(), 'get'], '/leagues', {});

    if (apiRes.ok) {
      yield put(successGetLeague(apiRes?.data?.data ? apiRes?.data?.data : []));
    } else {
      yield put(failureGetLeague('Something went wrong'));
    }
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetLeague(err));
  }
}

export function* updateLeagues(action: AnyAction): Generator<any, any, any> {
  try {
    const leagues = action.payload;

    const apiRes = yield call(
      [getApiClient(), 'put'],
      '/leagues',
      {
        leagues
      },
      {}
    );

    if (apiRes.ok) {
      message.success(translationMessages.en.leagues_updated_successfully);
      yield put(successUpdateLeague(leagues));
    } else {
      yield put(failureUpdateLeague('Something went wrong'));
    }
  } catch (err: any) {
    message.error(err.message);

    yield put(failureUpdateLeague(err));
  }
}

// Individual exports for testing
export default function* configContainerSaga() {
  yield takeLatest(requestGetConfig.toString(), getConfig);
  yield takeLatest(requestUpdateConfig.toString(), updateConfig);
  yield takeLatest(requestGetLeague.toString(), getLeague);
  yield takeLatest(requestUpdateLeague.toString(), updateLeagues);
}

export const configContainerSagaArr = [
  takeLatest(requestGetConfig.toString(), getConfig),
  takeLatest(requestUpdateConfig.toString(), updateConfig),
  takeLatest(requestGetLeague.toString(), getLeague),
  takeLatest(requestUpdateLeague.toString(), updateLeagues)
];
