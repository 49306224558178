import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  withdrawalsLoading: false,
  cardWithdrawalsLoading: false,
  withdrawals: [],
  cardWithdrawals: {},
  getWithdrawalsError: null,
  getCardWithdrawalsError: null
};

const withdrawalsSlice = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: {
    requestGetWithdrawals: {
      reducer: (state) => {
        state.withdrawalsLoading = true;
      },
      prepare
    },
    requestGetCardWithdrawals: {
      reducer: (state) => {
        state.withdrawalsLoading = true;
      },
      prepare
    },
    requestPutWithdrawals: {
      reducer: (state) => {
        state.withdrawalsLoading = true;
        state.getWithdrawalsError = null;
      },
      prepare
    },
    successGetWithdrawals(state, action) {
      state.withdrawalsLoading = false;
      state.withdrawals = action.payload;
      state.getWithdrawalsError = null;
    },
    failureGetWithdrawals(state, action) {
      state.withdrawalsLoading = false;
      state.withdrawals = [];
      state.getWithdrawalsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCardWithdrawals(state, action) {
      state.cardWithdrawalsLoading = false;
      state.cardWithdrawals = action.payload;
      state.getCardWithdrawalsError = null;
    },
    failureGetCardWithdrawals(state, action) {
      state.cardWithdrawalsLoading = false;
      state.cardWithdrawals = {};
      state.getCardWithdrawalsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successPutWithdrawals(state, action) {
      state.withdrawalsLoading = false;
      state.withdrawals = action.payload;
      state.getWithdrawalsError = null;
    },
    failurePutWithdrawals(state, action) {
      state.withdrawalsLoading = false;
      state.getWithdrawalsError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.withdrawalsLoading = false;
      state.withdrawals = [];
      state.getWithdrawalsError = null;
      state.cardWithdrawalsLoading = false;
      state.cardWithdrawals = {};
      state.getCardWithdrawalsError = null;
    }
  }
});

export const {
  requestGetWithdrawals,
  successGetWithdrawals,
  failureGetWithdrawals,
  requestGetCardWithdrawals,
  successGetCardWithdrawals,
  failureGetCardWithdrawals,
  successPutWithdrawals,
  failurePutWithdrawals,
  requestPutWithdrawals,
  resetState
} = withdrawalsSlice.actions;

export default withdrawalsSlice.reducer;
