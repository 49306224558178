import React, { useEffect } from 'react';
import { AnyAction, compose } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Skeleton } from 'antd';
import styled from 'styled-components';

import ConfigEditor from '../../components/ConfigEditor';
import { requestGetConfig, requestUpdateConfig, resetState } from '../ConfigContainer/reducer';
import {
  selectConfig,
  selectConfigLoading,
  selectUpdateConfigKey,
  selectUpdateConfigLoading
} from '../ConfigContainer/selectors';
import { If } from '@app/components';
import { getObjectDiff } from '@app/utils/uiUtils';
import { AVAILABLE_CONFIG_PAGES } from '@app/utils/constants';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
`;

type ConfigContainerProps = {
  config: any;
  configLoading: boolean;
  updateConfigLoading: boolean;
  updateConfigKey: string;
  dispatchGetConfig: () => AnyAction;
  dispatchUpdateConfig: (data: any) => AnyAction;
  dispatchResetState: () => AnyAction;
};

const ConfigContainer = (props: ConfigContainerProps) => {
  const intl = useIntl();

  useEffect(() => {
    props.dispatchGetConfig();
  }, []);

  const handleSaveData = (data: any, key: string, mfaCode: string) => {
    const newObject = {
      ...props.config,
      [key]: data[key]
    };

    const diff = getObjectDiff(props.config, newObject);
    const successMessages: any = [];

    if (diff.length && Object.keys(props.config).length > 0) {
      diff.forEach((diffKey) => {
        if (typeof props.config[diffKey] !== 'object' && !Array.isArray(props.config[diffKey])) {
          successMessages.push(
            intl.formatMessage(
              { id: 'config_value_change_success' },
              { property: diffKey, value1: props.config[diffKey].toString(), value2: data[diffKey].toString() }
            )
          );
        } else {
          successMessages.push(intl.formatMessage({ id: 'config_value_updated' }, { property: diffKey }));
        }
      });

      props.dispatchUpdateConfig({ data: newObject, successMessages, updateKey: key, mfaCode });
    }
  };

  return (
    <PageContainer>
      <If condition={Object.keys(props.config).length > 0 && !props.configLoading} otherwise={<Skeleton active />}>
        <ConfigEditor
          data={props.config}
          updateConfigKey={props.updateConfigKey}
          updateConfigLoading={props.updateConfigLoading}
          onSave={handleSaveData}
          page={AVAILABLE_CONFIG_PAGES.KILL_SWITCH_CONFIGS}
        />
      </If>
    </PageContainer>
  );
};

ConfigContainer.propTypes = {
  config: PropTypes.object,
  configLoading: PropTypes.bool,
  updateConfigLoading: PropTypes.bool,
  updateConfigKey: PropTypes.string,
  dispatchGetConfig: PropTypes.func,
  dispatchUpdateConfig: PropTypes.func,
  dispatchResetState: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  config: selectConfig(),
  updateConfigLoading: selectUpdateConfigLoading(),
  configLoading: selectConfigLoading(),
  updateConfigKey: selectUpdateConfigKey()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetConfig: () => dispatch(requestGetConfig({})),
    dispatchUpdateConfig: (data: any) => dispatch(requestUpdateConfig(data)),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(ConfigContainer);
