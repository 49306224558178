import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  transactionsLoading: false,
  cardTransactionsLoading: false,
  transactions: [],
  cardTransactions: {},
  getTransactionsError: null,
  getCardTransactionsError: null
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    requestGetTransactions: {
      reducer: (state) => {
        state.transactionsLoading = true;
      },
      prepare
    },
    successGetTransactions(state, action) {
      state.transactionsLoading = false;
      state.transactions = action.payload;
      state.getTransactionsError = null;
    },
    failureGetTransactions(state, action) {
      state.transactionsLoading = false;
      state.transactions = [];
      state.getTransactionsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCardTransactions(state, action) {
      state.cardTransactionsLoading = false;
      state.cardTransactions = action.payload;
      state.getCardTransactionsError = null;
    },
    failureGetCardTransactions(state, action) {
      state.cardTransactionsLoading = false;
      state.cardTransactions = {};
      state.getCardTransactionsError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.transactionsLoading = false;
      state.transactions = [];
      state.getTransactionsError = null;
      state.cardTransactionsLoading = false;
      state.cardTransactions = {};
      state.getCardTransactionsError = null;
    }
  }
});

export const {
  requestGetTransactions,
  successGetTransactions,
  failureGetTransactions,
  successGetCardTransactions,
  failureGetCardTransactions,
  resetState
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
