import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';

import { failureGetCardUsers, failureGetUsers, requestGetUsers, successGetCardUsers, successGetUsers } from './reducer';

import { getApiClient } from '@app/utils/apiUtils';
import { AnyAction } from '@reduxjs/toolkit';

export function* getUsers(action: AnyAction): Generator<any, any, any> {
  try {
    const { date } = action.payload;

    const apiRes = yield call(
      [getApiClient(), 'get'],
      '/users' + (date !== '' ? `?date=${moment(date).format('YYYY-MM-DD')}` : ''),
      {}
    );

    yield put(successGetUsers(apiRes?.data ? apiRes?.data?.data : []));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetUsers(err));
  }
}

export function* getCardUsers(): Generator<any, any, any> {
  try {
    const apiRes = yield call([getApiClient(), 'get'], '/app-users', {});

    yield put(successGetCardUsers(apiRes?.data?.data ? apiRes?.data?.data : {}));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetCardUsers(err));
  }
}

// Individual exports for testing
export default function* usersContainerSaga() {
  yield takeLatest(requestGetUsers.toString(), getUsers);
}

export const usersContainerSagaArr = [takeLatest(requestGetUsers.toString(), getUsers)];
