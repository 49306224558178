export const HEADER_HEIGHT = '7rem';
export const MIN_SIDEBAR_WIDTH = '4.5rem';
export const MOBILE_DRAWER_BREAKPOINT = 450;

export const AVAILABLE_CONFIG_PAGES = {
  TRANSACTION_CONFIGS: 'TRANSACTION_CONFIGS',
  KILL_SWITCH_CONFIGS: 'KILL_SWITCH_CONFIGS',
  SUPER_USERS: 'SUPER_USERS',
  STATE_CONFIGS: 'STATE_CONFIGS'
};
