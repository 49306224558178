import React from 'react';
import { colors } from '@app/themes';
import { Button, Input, Row, Col, Radio } from 'antd';
import styled from 'styled-components';
import { LeagueType, LeagueUpdateData } from '../../types';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { T } from '@app/components/T';
import { useIntl } from 'react-intl';

const LeagueName = styled.p`
  font-size: 1.2rem;
  color: ${colors.text};
`;

const LeagueOrderInput = styled(Input)`
  flex: auto;
  margin-left: 2rem;
  max-width: 3rem;
`;

const ModifyLeagueButton = styled(Button)``;

const LeagueLiveLabel = styled.p`
  font-size: 1.2rem;
  color: ${colors.text};
`;

const LeagueOrderLabel = styled.p`
  font-size: 1rem;
  color: ${colors.text};
  margin-left: 0.3rem;
`;

type LeagueItemProps = {
  league: LeagueType;
  index: number;
  total: number;
  updateLeague: (updateData: LeagueUpdateData) => void;
};

export const LeagueItem = (props: LeagueItemProps) => {
  return (
    <Row style={{ marginTop: '13px' }} align="middle">
      <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
        <LeagueName>{props.league.leagueName}</LeagueName>
      </Col>
      {/* <LeagueLiveLabel>
        <T id="is_live" />
      </LeagueLiveLabel>
      <Radio.Group
        value={props.league.isLive.toString()}
        // onChange={(e: any) => handleRadioChange(key, e)}
        style={{ marginLeft: '1rem' }}
      >
        <Radio value="true">
          <T id="true" />
        </Radio>
        <Radio value="false">
          <T id="false" />
        </Radio>
      </Radio.Group> */}
      <LeagueOrderLabel>
        <T id="order" />
      </LeagueOrderLabel>
      <LeagueOrderLabel>{props.league.order}</LeagueOrderLabel>
      <ModifyLeagueButton
        icon={<ArrowUpOutlined rev="span" />}
        style={{ marginLeft: '1rem' }}
        onClick={() =>
          props.updateLeague({
            id: props.league._id,
            direction: 'up'
          })
        }
        disabled={props.index === 0}
      />
      <ModifyLeagueButton
        icon={<ArrowDownOutlined rev="span" />}
        style={{ marginLeft: '1rem' }}
        onClick={() =>
          props.updateLeague({
            id: props.league._id,
            direction: 'down'
          })
        }
        disabled={props.index === props.total - 1}
      />
    </Row>
  );
};
