import { ApisauceInstance, create } from 'apisauce';

const apiClients: Record<string, ApisauceInstance> = {};

export const getApiClient = (type = 'betu') => apiClients[type];

export const generateApiClient = (type = 'betu') => {
  switch (type) {
    case 'betu':
      apiClients[type] = createApiClient(process.env.REACT_APP_API_URL!);
      return apiClients[type];
    default:
      apiClients.default = createApiClient(process.env.REACT_APP_API_URL!);
      return apiClients.default;
  }
};

export const createApiClient = (baseURL: string) => {
  const api = create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });

  return api;
};
