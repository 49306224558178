import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectConfigDomain = (state: any) => state.config || initialState;

export const selectConfigLoading = () =>
  createSelector(selectConfigDomain, (substate) => get(substate, 'configLoading'));

export const selectUpdateConfigLoading = () =>
  createSelector(selectConfigDomain, (substate) => get(substate, 'updateConfigLoading'));

export const selectConfig = () => createSelector(selectConfigDomain, (substate) => get(substate, 'config'));

export const selectUpdateConfigKey = () =>
  createSelector(selectConfigDomain, (substate) => get(substate, 'updateConfigKey'));

export const selectLeague = () => createSelector(selectConfigDomain, (substate) => get(substate, 'leagues'));

export const selectGetLeagueLoading = () =>
  createSelector(selectConfigDomain, (substate) => get(substate, 'getLeagueLoading'));

export const selectUpdateLeagueLoading = () =>
  createSelector(selectConfigDomain, (substate) => get(substate, 'updateLeagueLoading'));
