/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers, AnyAction } from 'redux';
import { createAction } from '@reduxjs/toolkit';

import languageProvider from '@containers/LanguageProvider/reducer';

import auth from '@containers/AuthContainer/reducer';
import metrics from '@containers/MetricsContainer/reducer';
import config from '@app/containers/ConfigContainer/reducer';
import notifications from '@containers/NotificationContainer/reducer';
import withdrawals from '@containers/WithdrawalContainer/reducer';
import users from '@containers/NewUsersContainer/reducer';
import transactions from '@containers/TransactionsContainer/reducer';

export const logoutAction = createAction('USER_LOGOUT');

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducer = {}) {
  const appReducer = combineReducers({
    ...injectedReducer,
    languageProvider,
    auth,
    metrics,
    config,
    notifications,
    withdrawals,
    users,
    transactions
  });

  const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === logoutAction.type) {
      return appReducer(undefined, { type: undefined });
    }

    return appReducer(state, action);
  };

  return rootReducer;
}
