import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectTransactionsDomain = (state: any) => state.transactions || initialState;

export const selectTransactionsLoading = () =>
  createSelector(selectTransactionsDomain, (substate) => get(substate, 'transactionsLoading'));

export const selectTransactions = () =>
  createSelector(selectTransactionsDomain, (substate) => get(substate, 'transactions'));

export const selectCardTransactions = () =>
  createSelector(selectTransactionsDomain, (substate) => get(substate, 'cardTransactions'));
