import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectWithdrawalsDomain = (state: any) => state.withdrawals || initialState;

export const selectWithdrawalsLoading = () =>
  createSelector(selectWithdrawalsDomain, (substate) => get(substate, 'withdrawalsLoading'));

export const selectWithdrawals = () =>
  createSelector(selectWithdrawalsDomain, (substate) => get(substate, 'withdrawals'));

export const selectCardWithdrawals = () =>
  createSelector(selectWithdrawalsDomain, (substate) => get(substate, 'cardWithdrawals'));

export const selectGetWithdrawalsError = () =>
  createSelector(selectWithdrawalsDomain, (substate) => get(substate, 'getWithdrawalsError'));
