/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const text = '#212529';
const secondaryText = '#FFFFFF';
const secondary = '#b0b0b0';
const success = '#28a745';
const error = '#dc3545';
const gotoStories = '#1890ff';
const cardBg = '#d8d8d8';
const primary = '#67CEC7';
const betuGreen = '#4a9691';
const neutral90 = '#111318';
const white = '#ffffff';

const colors = {
  transparent: 'rgba(0,0,0,0)',
  // Example colors:
  text,
  primary,
  secondary,
  success,
  error,
  secondaryText,
  gotoStories,
  cardBg,
  betuGreen,
  neutral90,
  white,
  theme: {
    lightMode: {
      primary,
      secondary
    },
    darkMode: {
      primary: secondary,
      secondary: primary
    }
  }
};
module.exports = colors;
