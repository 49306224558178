import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';

import {
  requestGetNotifications,
  successGetNotifications,
  failureGetNotifications,
  requestScheduleNotification,
  successScheduleNotification,
  failureScheduleNotification
} from './reducer';

import { getApiClient } from '@app/utils/apiUtils';
import { AnyAction } from '@reduxjs/toolkit';

export function* getNotifications(): Generator<any, any, any> {
  try {
    const apiRes = yield call([getApiClient(), 'get'], '/notification', {});

    yield put(successGetNotifications(apiRes?.data?.data ? apiRes?.data?.data : []));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetNotifications(err));
  }
}

export function* scheduleNotifications(action: AnyAction): Generator<any, any, any> {
  try {
    const { notificationBody } = action.payload;

    yield call([getApiClient(), 'post'], '/notification', { ...notificationBody }, {});

    yield call(getNotifications);

    yield put(successScheduleNotification());
  } catch (err: any) {
    message.error(err.message);

    yield put(failureScheduleNotification(err));
  }
}

// Individual exports for testing
export default function* notificationContainerSaga() {
  yield takeLatest(requestGetNotifications.toString(), getNotifications);
  yield takeLatest(requestScheduleNotification.toString(), scheduleNotifications);
}

export const notificationContainerSagaArr = [
  takeLatest(requestGetNotifications.toString(), getNotifications),
  takeLatest(requestScheduleNotification.toString(), scheduleNotifications)
];
