import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectNotificationsDomain = (state: any) => state.notifications || initialState;

export const selectScheduleNotificationsLoading = () =>
  createSelector(selectNotificationsDomain, (substate) => get(substate, 'savingNotifciation'));

export const selectGetNotificationsLoading = () =>
  createSelector(selectNotificationsDomain, (substate) => get(substate, 'getNotificationsLoading'));

export const selectNotifications = () =>
  createSelector(selectNotificationsDomain, (substate) => get(substate, 'notifications'));
