import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Table, Calendar, Button, Pagination } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { T } from '@app/components/T';
import { translationMessages } from '@app/i18n';
import { requestGetTransactions, resetState } from './reducer';
import { selectCardTransactions, selectTransactions, selectTransactionsLoading } from './selectors';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
`;

const TransactionsTitle = styled.h1``;

const DataTable = styled(Table)`
  margin-top: 3.75rem;
`;

type TransactionsContainerProps = {
  transactions: any;
  transactionsLoading: boolean;
  cardTransactions: any;
  dispatchGetTransactions: (date: string, page: number) => AnyAction;
  dispatchResetState: () => AnyAction;
  dispatchGetCardTransactions: () => AnyAction;
};

const TransactionsContainer = (props: TransactionsContainerProps) => {
  const intl = useIntl();
  const [searchDate, setSearchDate] = React.useState(
    moment().utc().tz('America/Chicago').subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    props.dispatchGetTransactions(searchDate, 1);
  }, []);

  const fetchTransactions = (date: any, page = 1) => {
    setSearchDate(date);
    setPage(page);
    props.dispatchGetTransactions(date.valueOf(), page);
  };

  const getColumns = () => {
    const fixedColumns: any = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        width: 120,
        fixed: 'left',
        render: (text: string) => moment.utc(text).tz('America/Chicago').format('DD/MM/YYYY'),
        filterDropdown: () => (
          <Calendar
            fullscreen={false}
            onChange={fetchTransactions}
            disabledDate={(date: any) => date && date.valueOf() > Date.now()}
          />
        )
      },
      {
        title: <span>{translationMessages.en.user_email}</span>,
        width: 250,
        dataIndex: 'user',
        render: (text: any) => `${text.email}`
      },
      {
        title: <span>{translationMessages.en.user_full_name}</span>,
        dataIndex: 'user',
        render: (text: any) => `${text.firstName} ${text.lastName}`
      },
      {
        title: <span>{translationMessages.en.transaction_type}</span>,
        dataIndex: 'type',
        render: (text: any) => text
      },
      {
        title: <span>{translationMessages.en.available_balance}</span>,
        dataIndex: 'currentBalance',
        render: (text: any) => Number(text.$numberDecimal).toFixed(2)
      },
      {
        title: <span>{translationMessages.en.transaction_amount}</span>,
        dataIndex: 'amount',
        render: (text: any) => Number(text.$numberDecimal).toFixed(2)
      },
      {
        title: <span>{translationMessages.en.status}</span>,
        dataIndex: 'status',
        render: (text: any) => text
      }
    ];

    return fixedColumns;
  };

  return (
    <PageContainer>
      <TransactionsTitle>
        <T id="transactions" />
      </TransactionsTitle>
      <DataTable
        bordered={true}
        virtual
        columns={getColumns().map((col: any) => ({ ...col, onCell: undefined }))}
        scroll={{ scrollToFirstRowOnChange: true, x: 1000, y: 500 }}
        rowKey="id"
        dataSource={props.transactions.transactions}
        pagination={false}
        loading={props.transactionsLoading}
      />
      <Pagination
        style={{ marginTop: '24px' }}
        defaultCurrent={1}
        current={page}
        total={props.transactions?.count ? props.transactions.count : 0}
        showSizeChanger={false}
        onChange={(pageNumber) => fetchTransactions(searchDate, pageNumber)}
      />
      <Button
        onClick={() =>
          fetchTransactions(moment().utc().tz('America/Chicago').subtract(30, 'days').format('YYYY-MM-DD'))
        }
        style={{ width: '200px', marginTop: '24px' }}
      >
        <T id="clear_filters" />
      </Button>
    </PageContainer>
  );
};

TransactionsContainer.propTypes = {
  transactions: PropTypes.array,
  transactionsLoading: PropTypes.bool,
  cardTransactions: PropTypes.object,
  dispatchGetTransactions: PropTypes.func,
  dispatchResetState: PropTypes.func,
  dispatchGetCardTransactions: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  transactions: selectTransactions(),
  transactionsLoading: selectTransactionsLoading(),
  cardTransactions: selectCardTransactions()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetTransactions: (date: string, page: number) => dispatch(requestGetTransactions({ date, page })),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(TransactionsContainer);
