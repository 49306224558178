import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Table, Calendar, Button } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { requestGetWithdrawals, resetState, requestPutWithdrawals } from './reducer';
import { selectWithdrawals, selectWithdrawalsLoading, selectGetWithdrawalsError } from './selectors';
import { T } from '@app/components/T';
import { translationMessages } from '@app/i18n';
import { If } from '@app/components';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
`;

const WithdrawalsTitle = styled.h1``;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const StatisticsCard = styled(Card)`
  width: 23%;
`;

const DataTable = styled(Table)`
  margin-top: 3.75rem;
`;

type WithdrawalContainerProps = {
  dispatchPutWithdrawals(user: any): AnyAction;
  withdrawals: any;
  withdrawalsLoading: boolean;
  dispatchGetWithdrawals: (date: string) => AnyAction;
  dispatchResetState: () => AnyAction;
  getWithdrawalsError: string;
};

const WithdrawalContainer = (props: WithdrawalContainerProps) => {
  const intl = useIntl();

  useEffect(() => {
    props.dispatchGetWithdrawals('');
  }, []);

  const fetchWithdrawals = (date: any) => {
    props.dispatchGetWithdrawals(date.valueOf());
  };

  const processPayment = (details: any) => {
    confirm(`Are you sure you want to process this payment?`) &&
      props.dispatchPutWithdrawals({ details, withdrawals: props.withdrawals });
  };

  const getColumns = () => {
    const fixedColumns: any = [
      {
        title: 'Date',
        dataIndex: 'transactionDetails',
        fixed: 'left',
        render: (text: any) => moment.utc(text.createdAt).tz('America/Chicago').format('DD/MM/YYYY HH:MM:SS z')
      },
      {
        title: <span>{translationMessages.en.user_id}</span>,
        dataIndex: 'userDetails',
        render: (user: any) => user.userId
      },
      {
        title: <span>{translationMessages.en.user_full_name}</span>,
        dataIndex: 'userDetails',
        render: (user: any) => `${user.firstName} ${user.lastName}`
      },
      {
        title: <span>{translationMessages.en.user_email}</span>,
        dataIndex: 'userDetails',
        render: (user: any) => user.userEmail
      },
      {
        title: <span>{translationMessages.en.mobile_number}</span>,
        dataIndex: 'userDetails',
        render: (user: any) => user.mobileNumber
      },
      {
        title: (
          <span>
            {translationMessages.en.available_balance} <br />
            {translationMessages.en.unit_dollar}
          </span>
        ),
        dataIndex: 'userDetails',
        render: (user: any) => Number(user.balance.$numberDecimal).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.amount} <br />
            {translationMessages.en.unit_dollar}
          </span>
        ),
        dataIndex: 'transactionDetails',
        render: (text: any) => Number(text.amount.$numberDecimal).toFixed(2)
      },
      {
        title: <span>{translationMessages.en.status}</span>,
        dataIndex: 'transactionDetails',
        render: (text: any) => text.status
      },
      {
        title: <span>{translationMessages.en.action}</span>,
        render: (details: any) => (
          <If condition={details.transactionDetails.status !== 'completed'} otherwise={<T id="processed" />}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                processPayment(details);
              }}
            >
              <T id="process_payment" />
            </Button>
          </If>
        )
      }
    ];

    return fixedColumns;
  };

  return (
    <PageContainer>
      <WithdrawalsTitle>
        <T id="withdrawals" />
      </WithdrawalsTitle>
      <DataTable
        bordered={true}
        virtual
        columns={getColumns().map((col: any) => ({ ...col, onCell: undefined }))}
        scroll={{ x: 1200, y: 500 }}
        rowKey="id"
        dataSource={props.withdrawals}
        pagination={false}
        loading={props.withdrawalsLoading}
      />
    </PageContainer>
  );
};

WithdrawalContainer.propTypes = {
  withdrawals: PropTypes.array,
  withdrawalsLoading: PropTypes.bool,
  getWithdrawalsError: PropTypes.string,
  dispatchGetWithdrawals: PropTypes.func,
  dispatchResetState: PropTypes.func,
  dispatchPutWithdrawals: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  withdrawals: selectWithdrawals(),
  withdrawalsLoading: selectWithdrawalsLoading(),
  getWithdrawalsError: selectGetWithdrawalsError()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetWithdrawals: (date: string) => dispatch(requestGetWithdrawals({ date })),
    dispatchPutWithdrawals: (details: any) => dispatch(requestPutWithdrawals({ details })),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(WithdrawalContainer);
