import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  metricsLoading: false,
  cardMetricsLoading: false,
  metrics: [],
  cardMetrics: {},
  getMetricsError: null,
  getCardMetricsError: null
};

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    requestGetMetrics: {
      reducer: (state) => {
        state.metricsLoading = true;
      },
      prepare
    },
    requestGetCardMetrics: {
      reducer: (state) => {
        state.metricsLoading = true;
      },
      prepare
    },
    successGetMetrics(state, action) {
      state.metricsLoading = false;
      state.metrics = action.payload;
      state.getMetricsError = null;
    },
    failureGetMetrics(state, action) {
      state.metricsLoading = false;
      state.metrics = [];
      state.getMetricsError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCardMetrics(state, action) {
      state.cardMetricsLoading = false;
      state.cardMetrics = action.payload;
      state.getCardMetricsError = null;
    },
    failureGetCardMetrics(state, action) {
      state.cardMetricsLoading = false;
      state.cardMetrics = {};
      state.getCardMetricsError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.metricsLoading = false;
      state.metrics = [];
      state.getMetricsError = null;
      state.cardMetricsLoading = false;
      state.cardMetrics = {};
      state.getCardMetricsError = null;
    }
  }
});

export const {
  requestGetMetrics,
  successGetMetrics,
  failureGetMetrics,
  requestGetCardMetrics,
  successGetCardMetrics,
  failureGetCardMetrics,
  resetState
} = metricsSlice.actions;

export default metricsSlice.reducer;
