import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Typography, DatePickerProps, Table } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AnyAction, compose } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { T } from '@app/components';
import { requestGetNotifications, requestScheduleNotification, resetState } from './reducer';
import { selectNotifications, selectScheduleNotificationsLoading, selectGetNotificationsLoading } from './selectors';
import { createStructuredSelector } from 'reselect';
import { translationMessages } from '@app/i18n';

const { Title } = Typography;

const NotificationCenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const DataTable = styled(Table)`
  margin-top: 3rem;
`;

type NotificationContainerProps = {
  notifications: any;
  scheduleNotificationsLoading: boolean;
  getNotificationsLoading: boolean;
  dispatchGetNotifications: () => AnyAction;
  dispatchScheduleNotification: (notification: any) => AnyAction;
  dispatchResetState: () => AnyAction;
};

const NotificationContainer = (props: NotificationContainerProps) => {
  const [time, setTime] = useState(moment());
  const [form] = Form.useForm();

  const intl = useIntl();

  useEffect(() => {
    props.dispatchGetNotifications();
  }, []);

  const getColumns = () => {
    const fixedColumns: any = [
      {
        title: translationMessages.en.scheduled_for,
        dataIndex: 'scheduledAt',
        width: 120,
        fixed: 'left',
        render: (text: string) => moment.utc(text).tz('America/Chicago').format('DD/MM/YYYY')
      },
      {
        title: <span>{translationMessages.en.notification_title}</span>,
        dataIndex: 'notificationTitle'
      },
      {
        title: <span>{translationMessages.en.notification_content}</span>,
        dataIndex: 'notificationBody'
      },
      {
        title: <span>{translationMessages.en.is_sent}</span>,
        dataIndex: 'isSent',
        render: (value: boolean) => (value ? 'Yes' : 'No')
      }
    ];

    return fixedColumns;
  };

  const onChange = (value: DatePickerProps['value'], dateString: string) => {
    const cdtMoment = moment.tz(dateString, 'DD/MM/YYYY hh:mm a', 'America/Chicago');

    const utcMoment = cdtMoment.utc();

    setTime(utcMoment);
  };

  const handleSubmit = (values: any) => {
    const payload = {
      notificationTitle: values.title,
      notificationBody: values.body,
      scheduleDate: time
    };

    props.dispatchScheduleNotification(payload);

    form.resetFields();
    setTime(moment());
  };

  return (
    <NotificationCenterContainer>
      <Title level={3}>
        <T id="notification_center" />
      </Title>

      <Form layout="vertical" form={form} onFinish={handleSubmit} style={{ maxWidth: 600 }}>
        <Form.Item label={intl.formatMessage({ id: 'notification_title' })} name="title">
          <Input placeholder={intl.formatMessage({ id: 'enter_notification_title' })} />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'notification_content' })} name="body">
          <Input.TextArea placeholder={intl.formatMessage({ id: 'enter_notification_body' })} />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'notification_time' })}>
          <DatePicker
            showTime
            onChange={onChange}
            format="DD/MM/YYYY hh:mm a"
            defaultValue={dayjs()}
            // disabledDate={(date: any) => date && date.valueOf() < Date.now()}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={props.scheduleNotificationsLoading}>
            <T id="schedule_notification" />
          </Button>
        </Form.Item>
      </Form>
      <DataTable
        bordered={true}
        virtual
        columns={getColumns().map((col: any) => ({ ...col, onCell: undefined }))}
        scroll={{ x: 2000, y: 300 }}
        rowKey="id"
        dataSource={props.notifications}
        pagination={false}
        loading={props.getNotificationsLoading}
      />
    </NotificationCenterContainer>
  );
};

NotificationContainer.propTypes = {
  notifications: PropTypes.array,
  scheduleNotificationsLoading: PropTypes.bool,
  getNotificationsLoading: PropTypes.bool,
  dispatchGetNotifications: PropTypes.func,
  dispatchScheduleNotification: PropTypes.func,
  dispatchResetState: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  notifications: selectNotifications(),
  scheduleNotificationsLoading: selectScheduleNotificationsLoading(),
  getNotificationsLoading: selectGetNotificationsLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetNotifications: () => dispatch(requestGetNotifications({})),
    dispatchScheduleNotification: (notificationBody: any) =>
      dispatch(requestScheduleNotification({ notificationBody })),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(NotificationContainer);
