import React, { useEffect, useState } from 'react';
import { AnyAction, compose } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal, Skeleton } from 'antd';
import styled from 'styled-components';
import { cloneDeep } from 'lodash-es';

import { requestGetLeague, requestUpdateLeague, resetState } from '../ConfigContainer/reducer';
import { selectGetLeagueLoading, selectLeague, selectUpdateLeagueLoading } from '../ConfigContainer/selectors';
import { If } from '@app/components';
import { LeagueItem } from './components/LeagueItem';
import { LeagueType, LeagueUpdateData } from './types';
import { T } from '@app/components/T';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
`;

const ModalTitle = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
`;

type LeagueConfigProps = {
  leagues: any;
  getLeagueLoading: boolean;
  updateLeagueLoading: boolean;
  dispatchGetLeague: () => AnyAction;
  dispatchUpdateLeague: (data: any) => AnyAction;
  dispatchResetState: () => AnyAction;
};

const LeagueConfigs = (props: LeagueConfigProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>({});
  const intl = useIntl();

  useEffect(() => {
    props.dispatchGetLeague();
  }, []);

  const handleUpdateLeague = (updateData: LeagueUpdateData) => {
    setModalVisible(true);
    setUpdateData(updateData);
  };

  const handleSaveData = () => {
    setModalVisible(false);
    setUpdateData({});

    const currentLeagues = props.leagues;
    const newLeagues = cloneDeep(currentLeagues);

    const currentIndex = currentLeagues.findIndex((league: any) => league._id === updateData.id);

    if (updateData.direction === 'down') {
      const temp = newLeagues[currentIndex + 1];

      newLeagues[currentIndex + 1] = newLeagues[currentIndex];
      newLeagues[currentIndex] = temp;
    } else {
      const temp = newLeagues[currentIndex - 1];

      newLeagues[currentIndex - 1] = newLeagues[currentIndex];
      newLeagues[currentIndex] = temp;
    }

    for (let i = 0; i < currentLeagues.length; i++) {
      newLeagues[i].order = i + 1;
    }

    props.dispatchUpdateLeague(newLeagues);
  };

  return (
    <PageContainer>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleSaveData}
        okText={intl.formatMessage({ id: 'yes' })}
        cancelText={intl.formatMessage({ id: 'no' })}
      >
        <ModalTitle>
          <T id="are_you_sure" />
        </ModalTitle>
      </Modal>
      <If condition={!props.getLeagueLoading && !props.updateLeagueLoading} otherwise={<Skeleton active />}>
        {props.leagues?.map((league: LeagueType, index: number) => (
          <LeagueItem
            league={league}
            key={league._id}
            index={index}
            total={props.leagues.length}
            updateLeague={handleUpdateLeague}
          />
        ))}
      </If>
    </PageContainer>
  );
};

LeagueConfigs.propTypes = {
  leagues: PropTypes.object,
  getLeagueLoading: PropTypes.bool,
  updateLeagueLoading: PropTypes.bool,
  dispatchGetLeague: PropTypes.func,
  dispatchUpdateLeague: PropTypes.func,
  dispatchResetState: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  leagues: selectLeague(),
  getLeagueLoading: selectGetLeagueLoading(),
  updateLeagueLoading: selectUpdateLeagueLoading()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetLeague: () => dispatch(requestGetLeague({})),
    dispatchUpdateLeague: (data: any) => dispatch(requestUpdateLeague(data)),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(LeagueConfigs);
