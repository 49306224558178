import React, { useEffect, useState } from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import {
  LineChartOutlined,
  LogoutOutlined,
  SlidersOutlined,
  UserAddOutlined,
  WalletOutlined,
  BankOutlined
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Auth } from 'aws-amplify';

import { T } from '@app/components';

import MetricsContainer from '../MetricsContainer';

import TransactionConfigs from '../TransactionConfigs';
import KillSwitchConfigs from '../KillSwitchConfigs';
import SuperUserConfigs from '../SuperUserConfigs';
import StateConfigs from '../StateConfigs';
import NotificationContainer from '../NotificationContainer';
import WithdrawalContainer from '../WithdrawalContainer';
import NewUsersContainer from '../NewUsersContainer';

import BetuLogo from '@app/images/betu-logo.svg';
import { logoutAction } from '@app/reducers';
import LeagueConfigs from '../LeagueConfigs';
import TransactionsContainer from '../TransactionsContainer';

const { Sider, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;

  && {
    .ant-menu-title-content {
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      white-space: nowrap !important;

      & a {
        display: flex;
        align-items: center;
        vertical-align: middle;

        & p {
          margin: 0;
        }
      }
    }
  }
`;

const SidebarLogo = styled.img`
  width: 80px;
  height: auto;
  margin: 20px;
`;

type DashboardContainerProps = {
  dispatchLogout: () => AnyAction;
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}

const DashboardContainer = (props: DashboardContainerProps) => {
  const [open, setOpen] = useState<string[]>([]);
  const [tab, setTab] = useState<string>('metrics');

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab: any = queryParams.get('tab');
    setTab(tab);
  }, []);

  const handleLogout = async () => {
    await Auth.signOut();
    props.dispatchLogout();
  };

  const handleNavigation = (tab: string) => {
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?tab=${tab}`;
    window.history.pushState({ path: newurl }, '', newurl);
    setTab(tab);
  };

  const getPageContent = (tab: string) => {
    let ActiveComponent: any = null;

    switch (tab) {
      case 'metrics':
        ActiveComponent = MetricsContainer;
        break;
      case 'transaction_configs':
        ActiveComponent = TransactionConfigs;
        break;
      case 'kill_switch_configs':
        ActiveComponent = KillSwitchConfigs;
        break;
      case 'super_users':
        ActiveComponent = SuperUserConfigs;
        break;
      case 'state_configs':
        ActiveComponent = StateConfigs;
        break;
      case 'league_configs':
        ActiveComponent = LeagueConfigs;
        break;
      case 'notification_center':
        ActiveComponent = NotificationContainer;
        break;
      case 'withdrawals':
        ActiveComponent = WithdrawalContainer;
        break;
      case 'users':
        ActiveComponent = NewUsersContainer;
        break;
      case 'transactions':
        ActiveComponent = TransactionsContainer;
        break;
      default:
        ActiveComponent = MetricsContainer;
    }

    return <ActiveComponent />;
  };

  const items: MenuItem[] = [
    getItem(
      <MenuItem onClick={() => handleNavigation('metrics')}>
        <T id="dashboard" />
      </MenuItem>,
      'metrics',
      <LineChartOutlined rev={false} />
    ),
    getItem(<T id="config" />, 'sub1', <SlidersOutlined rev={false} />, [
      getItem(
        <MenuItem onClick={() => handleNavigation('transaction_configs')}>
          <T id="transaction_configs" />
        </MenuItem>,
        'transaction_configs'
      ),
      getItem(
        <MenuItem onClick={() => handleNavigation('kill_switch_configs')}>
          <T id="kill_switch_configs" />
        </MenuItem>,
        'kill_switch_configs'
      ),
      getItem(
        <MenuItem onClick={() => handleNavigation('super_users')}>
          <T id="super_users" />
        </MenuItem>,
        'super_users'
      ),
      getItem(
        <MenuItem onClick={() => handleNavigation('state_configs')}>
          <T id="state_configs" />
        </MenuItem>,
        'state_configs'
      ),
      getItem(
        <MenuItem onClick={() => handleNavigation('league_configs')}>
          <T id="league_configs" />
        </MenuItem>,
        'league_configs'
      ),
      getItem(
        <MenuItem onClick={() => handleNavigation('notification_center')}>
          <T id="notification_center" />
        </MenuItem>,
        'notification_center'
      )
    ]),
    getItem(
      <MenuItem onClick={() => handleNavigation('withdrawals')}>
        <T id="withdrawals" />
      </MenuItem>,
      'withdrawals',
      <BankOutlined rev={false} />
    ),
    getItem(
      <MenuItem onClick={() => handleNavigation('users')}>
        <T id="users" />
      </MenuItem>,
      'users',
      <UserAddOutlined rev={false} />
    ),
    getItem(
      <MenuItem onClick={() => handleNavigation('transactions')}>
        <T id="transactions" />
      </MenuItem>,
      'transactions',
      <WalletOutlined rev={false} />
    ),
    getItem(
      <MenuItem onClick={handleLogout}>
        <T id="logout" />
      </MenuItem>,
      '1',
      <LogoutOutlined rev={false} />
    )
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider breakpoint="lg" collapsedWidth="0">
        <SidebarLogo src={BetuLogo} />
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          activeKey={'league_configs'}
          openKeys={open}
          onOpenChange={(openKeys: string[]) => setOpen(openKeys)}
        />
      </Sider>
      <Layout>
        <Content>
          <div style={{ background: '#fff', minHeight: '100vh' }}>{getPageContent(tab)}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

DashboardContainer.propTypes = {
  dispatchLogout: PropTypes.func
};

DashboardContainer.defaultProps = {};

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchLogout: () => dispatch(logoutAction())
  };
}
const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, injectIntl)(DashboardContainer);

export const DashboardContainerTest = DashboardContainer;
