import { isEqual } from 'lodash-es';

export const cleanUpRoute = (route: string) => {
  let newRoute: any = route.split('/');

  if (newRoute[newRoute.length - 1] === '/') {
    newRoute.pop();
  }

  newRoute = newRoute.join('/');

  return newRoute;
};

export const getObjectDiff = (obj1: any, obj2: any) => {
  const diff = Object.keys(obj1).reduce((result, key: string) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const isValidEmail = (email: string) => {
  // A simple regex for email validation
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};
