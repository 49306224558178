export type RouteConstant = {
  route: string;
  exact?: boolean;
  isProtected?: boolean;
  props?: object;
};

const routeConstants: Record<string, RouteConstant> = {
  dashboard: {
    route: '/',
    exact: true,
    isProtected: true
  },
  login: {
    route: '/login',
    exact: true,
    isProtected: false
  },
  launch: {
    route: '/launch/:launchId',
    exact: true
  }
};

export type RouteKeys = keyof typeof routeConstants;

export default routeConstants;
