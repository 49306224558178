import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';

import {
  failureGetCardMetrics,
  failureGetMetrics,
  requestGetCardMetrics,
  requestGetMetrics,
  successGetCardMetrics,
  successGetMetrics
} from './reducer';

import { getApiClient } from '@app/utils/apiUtils';
import { AnyAction } from '@reduxjs/toolkit';

export function* getMetrics(action: AnyAction): Generator<any, any, any> {
  try {
    const { date } = action.payload;

    const apiRes = yield call(
      [getApiClient(), 'get'],
      '/metrics' + (date !== '' ? `?date=${moment(date).format('YYYY-MM-DD')}` : ''),
      {}
    );

    yield put(successGetMetrics(apiRes?.data?.data ? apiRes?.data?.data : []));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetMetrics(err));
  }
}

export function* getCardMetrics(): Generator<any, any, any> {
  try {
    const apiRes = yield call([getApiClient(), 'get'], '/app-metrics', {});

    yield put(successGetCardMetrics(apiRes?.data?.data ? apiRes?.data?.data : {}));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetCardMetrics(err));
  }
}

// Individual exports for testing
export default function* metricsContainerSaga() {
  yield takeLatest(requestGetMetrics.toString(), getMetrics);
  yield takeLatest(requestGetCardMetrics.toString(), getCardMetrics);
}

export const metricsContainerSagaArr = [
  takeLatest(requestGetMetrics.toString(), getMetrics),
  takeLatest(requestGetCardMetrics.toString(), getCardMetrics)
];
