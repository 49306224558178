import { createGlobalStyle } from 'styled-components';
import { colors } from '@app/themes';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  * {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  }

  body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
}




  #app {
    background-color: ${colors.secondaryText};
    min-height: 100%;
    min-width: 100%;
  }

  p {
    margin-top: 0;
  }

  p,
  span,
  button,
  label {
    line-height: 1.5em;
    margin-bottom: 0;
  }


  .ant-table-cell-row-hover {
    background: #eeeeee !important;
  }

  .ant-card-head-title {
    white-space: pre-wrap !important;
  }

  .ant-menu-title-content{
    white-space: pre-wrap !important;
  }

  .ant-table-filter-dropdown{
    max-width: 300px !important;
  }
`;

export default GlobalStyle;
