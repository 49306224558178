import { all } from 'redux-saga/effects';
import { authContainerSagaArr } from '../AuthContainer/saga';
import { configContainerSagaArr } from '../ConfigContainer/saga';
import { metricsContainerSagaArr } from '../MetricsContainer/saga';
import { notificationContainerSagaArr } from '../NotificationContainer/saga';
import { withdrawalsContainerSagaArr } from '../WithdrawalContainer/saga';
import { usersContainerSagaArr } from '../NewUsersContainer/saga';
import { transactionsContainerSagaArr } from '../TransactionsContainer/saga';

export default function* RootSaga() {
  yield all([
    ...authContainerSagaArr,
    ...metricsContainerSagaArr,
    ...configContainerSagaArr,
    ...notificationContainerSagaArr,
    ...withdrawalsContainerSagaArr,
    ...usersContainerSagaArr,
    ...transactionsContainerSagaArr
  ]);
}
