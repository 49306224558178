import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  configLoading: false,
  updateConfigLoading: false,
  updateLeagueLoading: false,
  leagues: [],
  getLeagueLoading: false,
  getLeagueError: null,
  updateLeagueError: null,
  updateConfigKey: null,
  config: {},
  getConfigError: null,
  updateConfigError: null
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    requestGetConfig: {
      reducer: (state) => {
        state.configLoading = true;
      },
      prepare
    },
    requestUpdateConfig: {
      reducer: (state) => {
        state.updateConfigLoading = true;
      },
      prepare
    },
    requestUpdateLeague: {
      reducer: (state) => {
        state.updateLeagueLoading = true;
      },
      prepare
    },
    requestGetLeague: {
      reducer: (state) => {
        state.getLeagueLoading = true;
      },
      prepare
    },
    successGetLeague(state, action) {
      state.leagues = action.payload;
      state.getLeagueLoading = false;
      state.getLeagueError = null;
    },
    failureGetLeague(state, action) {
      state.getLeagueLoading = false;
      state.getLeagueError = get(action.payload, 'message', 'something_went_wrong');
    },
    successUpdateLeague(state, action) {
      state.leagues = action.payload;
      state.updateLeagueLoading = false;
      state.updateLeagueError = null;
    },
    failureUpdateLeague(state, action) {
      state.updateLeagueLoading = false;
      state.updateLeagueError = get(action.payload, 'message', 'something_went_wrong');
    },
    setUpdateKey(state, action) {
      state.updateConfigKey = action.payload;
    },
    successUpdateConfig(state, action) {
      state.config = action.payload;
      state.updateConfigLoading = false;
      state.updateConfigError = null;
      state.updateConfigKey = null;
    },
    failureUpdateConfig(state, action) {
      state.updateConfigLoading = false;
      state.updateConfigError = get(action.payload, 'message', 'something_went_wrong');
      state.updateConfigKey = null;
    },
    successGetConfig(state, action) {
      state.configLoading = false;
      state.config = action.payload;
      state.getConfigError = null;
    },
    failureGetConfig(state, action) {
      state.configLoading = false;
      state.config = {};
      state.getConfigError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.configLoading = false;
      state.config = {};
      state.getConfigError = null;
      state.updateConfigError = null;
    }
  }
});

export const {
  requestGetConfig,
  successGetConfig,
  failureGetConfig,
  setUpdateKey,
  requestUpdateConfig,
  successUpdateConfig,
  failureUpdateConfig,
  requestUpdateLeague,
  successUpdateLeague,
  failureUpdateLeague,
  requestGetLeague,
  successGetLeague,
  failureGetLeague,
  resetState
} = configSlice.actions;

export default configSlice.reducer;
