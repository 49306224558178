import React from 'react';
import NotFound from '@containers/NotFoundPage/Loadable';
import DashboardContainer from '@app/containers/DashboardContainer';
import routeConstants, { RouteConstant } from '@utils/routeConstants';
import LoginContainer from './containers/LoginContainer';

type RouteConfig = Record<string, { component: React.FC<any> } & Partial<RouteConstant>>;

export const routeConfig: RouteConfig = {
  dashboard: {
    component: DashboardContainer,
    ...routeConstants.dashboard
  },
  login: {
    component: LoginContainer,
    ...routeConstants.login
  },
  notFoundPage: {
    component: NotFound,
    route: '/'
  }
};
