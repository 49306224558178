import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectAuthDomain = (state: any) => state.auth || initialState;

export const selectUserData = () => createSelector(selectAuthDomain, (substate) => get(substate, 'userData'));

export const selectLoginLoading = () => createSelector(selectAuthDomain, (substate) => get(substate, 'loading'));

export const selectShowMfa = () => createSelector(selectAuthDomain, (substate) => get(substate, 'showMfa'));

export const selectIsLoggedIn = () => createSelector(selectAuthDomain, (substate) => get(substate, 'isLoggedIn'));

export const selectQrImage = () => createSelector(selectAuthDomain, (substate) => get(substate, 'qrImage'));

export const selectShowQr = () => createSelector(selectAuthDomain, (substate) => get(substate, 'showQr'));
