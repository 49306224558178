import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  getNotificationsLoading: false,
  savingNotifciation: false,
  notifications: [],
  getNotificationError: null,
  saveNotificationError: null
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    requestGetNotifications: {
      reducer: (state) => {
        state.getNotificationsLoading = true;
      },
      prepare
    },
    requestScheduleNotification: {
      reducer: (state) => {
        state.savingNotifciation = true;
      },
      prepare
    },
    successScheduleNotification(state) {
      state.savingNotifciation = false;
      state.saveNotificationError = null;
    },
    failureScheduleNotification(state, action) {
      state.savingNotifciation = false;
      state.saveNotificationError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetNotifications(state, action) {
      state.getNotificationsLoading = false;
      state.notifications = action.payload;
      state.getNotificationError = null;
    },
    failureGetNotifications(state, action) {
      state.getNotificationsLoading = false;
      state.notifications = [];
      state.getNotificationError = get(action.payload, 'message', 'something_went_wrong');
    },

    resetState(state) {
      state.getNotificationsLoading = false;
      state.savingNotifciation = false;
      state.notifications = [];
      state.getNotificationError = null;
      state.saveNotificationError = null;
    }
  }
});

export const {
  requestGetNotifications,
  successGetNotifications,
  failureGetNotifications,
  requestScheduleNotification,
  successScheduleNotification,
  failureScheduleNotification,
  resetState
} = notificationSlice.actions;

export default notificationSlice.reducer;
