import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';

import {
  failureGetCardWithdrawals,
  failureGetWithdrawals,
  requestGetCardWithdrawals,
  requestGetWithdrawals,
  successGetCardWithdrawals,
  successGetWithdrawals,
  successPutWithdrawals,
  failurePutWithdrawals,
  requestPutWithdrawals
} from './reducer';

import { getApiClient } from '@app/utils/apiUtils';
import { AnyAction } from '@reduxjs/toolkit';

export function* getWithdrawals(action: AnyAction): Generator<any, any, any> {
  try {
    const { date } = action.payload;

    const apiRes = yield call([getApiClient(), 'get'], '/manual-transactions', '');

    yield put(successGetWithdrawals(apiRes?.data?.data ? apiRes?.data?.data : []));
  } catch (err: any) {
    message.error(err.message);

    yield put(failureGetWithdrawals(err));
  }
}

export function* putWithdrawals(action: AnyAction): Generator<any, any, any> {
  try {
    const { details } = action.payload;
    const body = {
      email: details?.details?.userDetails?.userEmail,
      amount: details?.details?.transactionDetails?.amount?.$numberDecimal,
      id: details.details.transactionDetails.transactionId
    };
    const apiRes = yield call([getApiClient(), 'put'], '/transaction', { ...body });
    if (apiRes.ok) {
      const apiResp = details.withdrawals.map((item: any) => {
        if (item.transactionDetails.transactionId === details.details.transactionDetails.transactionId) {
          return {
            transactionDetails: {
              ...item.transactionDetails,
              status: 'completed'
            },
            userDetails: {
              ...item.userDetails,
              balance: {
                ...item.userDetails.balance,
                $numberDecimal: Number(
                  item.userDetails.balance.$numberDecimal - item.transactionDetails.amount.$numberDecimal
                ).toFixed(2)
              }
            }
          };
        }
        return item;
      });
      yield put(successPutWithdrawals(apiResp || []));
    }
    yield put(failurePutWithdrawals(apiRes.data.error));
  } catch (err: any) {
    message.error(err.message);

    yield put(failurePutWithdrawals(err));
  }
}

// Individual exports for testing
export default function* withdrawalsContainerSaga() {
  yield takeLatest(requestGetWithdrawals.toString(), getWithdrawals);
  yield takeLatest(requestPutWithdrawals.toString(), putWithdrawals);
}

export const withdrawalsContainerSagaArr = [
  takeLatest(requestGetWithdrawals.toString(), getWithdrawals),
  takeLatest(requestPutWithdrawals.toString(), putWithdrawals)
];
