import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Table, Calendar, Button } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { T } from '@app/components/T';
import { translationMessages } from '@app/i18n';
import { requestGetUsers, resetState } from './reducer';
import { selectCardUsers, selectUsers, selectUsersLoading } from './selectors';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
`;

const UsersTitle = styled.h1``;

const DataTable = styled(Table)`
  margin-top: 3.75rem;
`;

type NewUserContainerProps = {
  users: any;
  usersLoading: boolean;
  cardUsers: any;
  dispatchGetUsers: (date: string) => AnyAction;
  dispatchResetState: () => AnyAction;
  dispatchGetCardUsers: () => AnyAction;
};

const NewUserContainer = (props: NewUserContainerProps) => {
  const intl = useIntl();

  useEffect(() => {
    props.dispatchGetUsers('');
  }, []);

  const fetchUsers = (date: any) => {
    props.dispatchGetUsers(date.valueOf());
  };

  const getColumns = () => {
    const fixedColumns: any = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        width: 120,
        fixed: 'left',
        render: (text: string) => moment.utc(text).tz('America/Chicago').format('DD/MM/YYYY'),
        filterDropdown: () => (
          <Calendar
            fullscreen={false}
            onChange={fetchUsers}
            disabledDate={(date: any) => date && date.valueOf() > Date.now()}
          />
        )
      },
      {
        title: <span>{translationMessages.en.user_email}</span>,
        width: 350,
        dataIndex: 'email',
        render: (text: string) => text
      },
      {
        title: <span>{translationMessages.en.user_full_name}</span>,
        render: (text: any) => `${text.firstName} ${text.lastName}`
      }
    ];

    return fixedColumns;
  };

  return (
    <PageContainer>
      <UsersTitle>
        <T id="users" />
      </UsersTitle>
      <DataTable
        bordered={true}
        virtual
        columns={getColumns().map((col: any) => ({ ...col, onCell: undefined }))}
        scroll={{ scrollToFirstRowOnChange: true, x: 1000, y: 500 }}
        rowKey="id"
        dataSource={props.users}
        loading={props.usersLoading}
      />
      <Button onClick={() => fetchUsers('')} style={{ width: '200px' }}>
        Clear filters
      </Button>
    </PageContainer>
  );
};

NewUserContainer.propTypes = {
  users: PropTypes.array,
  usersLoading: PropTypes.bool,
  cardUsers: PropTypes.object,
  dispatchGetUsers: PropTypes.func,
  dispatchResetState: PropTypes.func,
  dispatchGetCardUsers: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  users: selectUsers(),
  usersLoading: selectUsersLoading(),
  cardUsers: selectCardUsers()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetUsers: (date: string) => dispatch(requestGetUsers({ date })),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(NewUserContainer);
