import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { T } from '@app/components/T';
import { injectIntl, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { requestConfirmMfa, requestLoginUser, resetState } from '../AuthContainer/reducer';
import { connect } from 'react-redux';
import {
  selectLoginLoading,
  selectQrImage,
  selectShowMfa,
  selectShowQr,
  selectUserData
} from '../AuthContainer/selectors';
import { If } from '@app/components';
import { colors } from '@app/themes';
import BetuLogo from '@images/betu-logo.svg';

const { Title } = Typography;

type LoginContainerProps = {
  dispatchLogin: (email: string, password: string) => AnyAction;
  dispatchConfirmMfa: (mfa: string, newSetup: boolean, user: any) => AnyAction;
  dispatchResetState: () => AnyAction;
  user: object;
  loginLoading: boolean;
  showMfa: boolean;
  qrImage: string;
  showQr: boolean;
};

const FormTitle = styled(Title)`
  text-align: center;
  color: ${colors.white} !important;
`;

const CenteredRow = styled(Row)`
  height: 100vh;
  background: linear-gradient(to right bottom, ${colors.betuGreen}, rgba(0, 0, 0, 0)) ${colors.neutral90};
`;

const LoginForm = styled(Form)`
  max-width: 18.75rem;
  margin: 0 auto;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
  background: linear-gradient(to right bottom, ${colors.white}, ${colors.primary});
  border: none !important;
  box-shadow: none !important;
  color: ${colors.neutral90};
`;

const QrCodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AppLogo = styled.img`
  width: 15rem;
  display: flex;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
`;

const LoginContainer = (props: LoginContainerProps) => {
  const intl = useIntl();

  const [otpCode, setOtpCode] = useState('');

  const onFinish = (values: any) => {
    // Handle login logic here

    // Auth.signIn(values.email, values.password,{

    // })
    //   .then((value) => {
    //     console.log('Value ', value);
    //   })
    //   .catch((err) => {
    //     console.log('Error ', err);
    //   });

    if (!props.showMfa) {
      props.dispatchLogin(values.email, values.password);
    } else {
      props.dispatchConfirmMfa(values.mfa, false, props.user);
    }
  };

  return (
    <CenteredRow justify="center" align="middle">
      <Col span={8} style={{ alignItems: 'center' }}>
        <If
          condition={!props.showQr}
          otherwise={
            <QrCodeContainer>
              <img src={props.qrImage} />

              <Input
                prefix={<LockOutlined className="site-form-item-icon" rev={undefined} />}
                type="number"
                placeholder={intl.formatMessage({ id: 'mfa_placeholder' })}
                style={{ marginTop: '10px' }}
                onChange={(e) => setOtpCode(e.target.value)}
              />
              <FullWidthButton
                type="primary"
                htmlType="submit"
                loading={props.loginLoading}
                style={{ marginTop: '10px' }}
                onClick={() => props.dispatchConfirmMfa(otpCode, true, props.user)}
                disabled={otpCode.trim().length !== 6}
              >
                <T id="login" />
              </FullWidthButton>
            </QrCodeContainer>
          }
        >
          <AppLogo src={BetuLogo} />
          <FormTitle level={1}>
            <T id="admin_portal_title" />
          </FormTitle>
          <LoginForm name="normal_login" initialValues={{ remember: true }} onFinish={onFinish}>
            <If
              condition={!props.showMfa}
              otherwise={
                <Form.Item
                  name="mfa"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'no_mfa_error' })
                    }
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" rev={undefined} />}
                    placeholder={intl.formatMessage({ id: 'mfa_placeholder' })}
                  />
                </Form.Item>
              }
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'no_email_error' })
                  }
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" rev={undefined} />}
                  placeholder={intl.formatMessage({ id: 'email_placeholder' })}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'no_password_error' })
                  }
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" rev={undefined} />}
                  type="password"
                  placeholder={intl.formatMessage({ id: 'password_placeholder' })}
                />
              </Form.Item>
            </If>

            <Form.Item>
              <FullWidthButton type="primary" htmlType="submit" loading={props.loginLoading}>
                <T id="login" />
              </FullWidthButton>
            </Form.Item>
          </LoginForm>
        </If>
      </Col>
    </CenteredRow>
  );
};

LoginContainer.propTypes = {
  user: PropTypes.object,
  loginLoading: PropTypes.bool,
  showMfa: PropTypes.bool,
  qrImage: PropTypes.string,
  showQr: PropTypes.bool,
  dispatchLogin: PropTypes.func,
  dispatchConfirmMfa: PropTypes.func,
  dispatchResetState: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  user: selectUserData(),
  loginLoading: selectLoginLoading(),
  showMfa: selectShowMfa(),
  qrImage: selectQrImage(),
  showQr: selectShowQr()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchLogin: (email: string, password: string) => dispatch(requestLoginUser({ email, password })),
    dispatchConfirmMfa: (mfa: string, newSetup: boolean, user: any) =>
      dispatch(requestConfirmMfa({ mfa, newSetup, user })),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(LoginContainer);
