/**
 *
 * T
 *
 */

import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import If from '@components/If';
import { fonts } from '@themes/index';

type FontStyleType = keyof typeof fonts.style;

interface TProps {
  type?: FontStyleType;
  text?: string;
  id?: string;
  values?: Record<string, React.ReactNode>;
}

export const T = ({ type = 'standard', text, id, values = {} }: TProps) => (
  <If condition={id} otherwise={text}>
    <FormattedMessage id={id} values={values} />
  </If>
);

T.propTypes = {
  id: PropTypes.string,
  values: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(fonts.style))
};

T.defaultProps = {
  values: {},
  type: 'standard'
};

const TextComponent = memo(T);
export default TextComponent;
