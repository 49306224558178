import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  usersLoading: false,
  cardUsersLoading: false,
  users: [],
  cardUsers: {},
  getUsersError: null,
  getCardUsersError: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    requestGetUsers: {
      reducer: (state) => {
        state.usersLoading = true;
      },
      prepare
    },
    successGetUsers(state, action) {
      state.usersLoading = false;
      state.users = action.payload;
      state.getUsersError = null;
    },
    failureGetUsers(state, action) {
      state.usersLoading = false;
      state.users = [];
      state.getUsersError = get(action.payload, 'message', 'something_went_wrong');
    },
    successGetCardUsers(state, action) {
      state.cardUsersLoading = false;
      state.cardUsers = action.payload;
      state.getCardUsersError = null;
    },
    failureGetCardUsers(state, action) {
      state.cardUsersLoading = false;
      state.cardUsers = {};
      state.getCardUsersError = get(action.payload, 'message', 'something_went_wrong');
    },
    resetState(state) {
      state.usersLoading = false;
      state.users = [];
      state.getUsersError = null;
      state.cardUsersLoading = false;
      state.cardUsers = {};
      state.getCardUsersError = null;
    }
  }
});

export const {
  requestGetUsers,
  successGetUsers,
  failureGetUsers,
  successGetCardUsers,
  failureGetCardUsers,
  resetState
} = usersSlice.actions;

export default usersSlice.reducer;
