import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectMetricsDomain = (state: any) => state.metrics || initialState;

export const selectMetricsLoading = () =>
  createSelector(selectMetricsDomain, (substate) => get(substate, 'metricsLoading'));

export const selectMetrics = () => createSelector(selectMetricsDomain, (substate) => get(substate, 'metrics'));

export const selectCardMetrics = () => createSelector(selectMetricsDomain, (substate) => get(substate, 'cardMetrics'));
