import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { InputNumber, Button, Row, Col, Typography, Tooltip, List, Select, Radio, Input, Modal } from 'antd';
import styled from 'styled-components';
import { snakeCase } from 'lodash-es';
import { QuestionCircleOutlined, MinusCircleOutlined, LockOutlined } from '@ant-design/icons';

import { T } from '@app/components/T';
import { AVAILABLE_CONFIG_PAGES } from '@app/utils/constants';
import { allStates } from '@app/utils/states';

const { Title } = Typography;

const { Option } = Select;

const InputContainer = styled.div`
  width: 100%;
`;

const InputItem = styled.div`
  width: 50%;
  margin-top: 13px;
`;

const PropertyTitle = styled.p``;

const ModalTitle = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
`;

const ModalSubtitle = styled.p`
  font-size: 0.9rem;
`;

type SettingsProps = {
  data: {
    [key: string]: string | boolean | string[] | Record<string, number>;
  };
  page: string;
  updateConfigLoading: boolean;
  updateConfigKey: string;
  onSave: (data: any, key: string, mfa: string) => void;
};

let configSettings = {
  settings: '',
  key: ''
};

const ConfigEditor: React.FC<SettingsProps> = ({
  data,
  updateConfigLoading,
  updateConfigKey,
  page,
  onSave
}: SettingsProps) => {
  const intl = useIntl();
  const [settings, setSettings] = useState(data);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [mfaCode, setMfaCode] = useState('');

  useEffect(() => {
    const selected = Object.keys(settings.legalStatesAndAge);
    setSelectedStates(selected);
  }, [settings]);

  const handleRadioChange = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => ({ ...prev, [field]: e.target.value === 'true' }));
  };

  const handleInputChange = (field: string, value: number) => {
    if (value) {
      setSettings((prev) => ({ ...prev, [field]: value.toString() }));
    }
  };

  const handleEmailChange = (index: number, email: string) => {
    const updatedEmails = [...(settings.whiteListedEmails as string[])];
    updatedEmails[index] = email;
    setSettings((prev) => ({ ...prev, whiteListedEmails: updatedEmails }));
  };

  const handleAddEmail = () => {
    setSettings((prev) => ({ ...prev, whiteListedEmails: [...(prev.whiteListedEmails as string[]), ''] }));
  };

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = [...(settings.whiteListedEmails as string[])];
    updatedEmails.splice(index, 1);
    setSettings((prev) => ({ ...prev, whiteListedEmails: updatedEmails }));
  };

  const removeEmpty = (obj: Object) => {
    return Object.fromEntries(Object.entries(obj).filter(([k, _]) => k !== ''));
  };

  const handleLegalStateChange = (state: string, age: number | null) => {
    setSettings((prev) => ({
      ...prev,
      legalStatesAndAge: { ...(removeEmpty(prev.legalStatesAndAge) as Record<string, number>), [state]: age as number }
    }));
  };

  const handleAddState = () => {
    setSettings((prev) => ({
      ...prev,
      legalStatesAndAge: { ...(prev.legalStatesAndAge as Record<string, number>), '': 18 }
    }));
  };

  const handleRemoveState = (state: string) => {
    const updatedStates = { ...(settings.legalStatesAndAge as Record<string, number>) };
    delete updatedStates[state];
    setSettings((prev) => ({ ...prev, legalStatesAndAge: updatedStates }));
  };

  const handleSave = (settings: any, key: string) => {
    setModalVisible(true);
    configSettings = {
      settings,
      key
    };
  };

  const saveData = () => {
    setModalVisible(false);
    onSave(configSettings.settings, configSettings.key, mfaCode);
  };

  const availableStates = allStates.filter((state: string) => !selectedStates.includes(state));

  const getUi = () => {
    switch (page) {
      case AVAILABLE_CONFIG_PAGES.TRANSACTION_CONFIGS:
        return (
          <div>
            <Title level={3}>{intl.formatMessage({ id: 'transaction_configs_title' })}</Title>
            <InputContainer>
              {Object.entries(settings)
                .filter(([_, value]) => typeof value === 'string')
                .map(([key, value]) => (
                  <InputItem key={key}>
                    <Row align={'middle'}>
                      <PropertyTitle>
                        {intl.formatMessage({
                          id: `${snakeCase(key)}_label`
                        })}
                      </PropertyTitle>

                      <Tooltip
                        title={intl.formatMessage({
                          id: `${snakeCase(key)}_tip`
                        })}
                      >
                        <QuestionCircleOutlined rev={false} style={{ marginLeft: '10px' }} />
                      </Tooltip>
                      <InputNumber
                        style={{ marginLeft: 'auto' }}
                        value={parseInt(value as string)}
                        onChange={(val: number | null) => handleInputChange(key, val as number)}
                        min={0}
                      />
                      <Button
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleSave(settings, key)}
                        loading={updateConfigLoading && updateConfigKey === key}
                      >
                        <T id="save" />
                      </Button>
                    </Row>
                  </InputItem>
                ))}
            </InputContainer>
          </div>
        );
      case AVAILABLE_CONFIG_PAGES.KILL_SWITCH_CONFIGS:
        return (
          <div>
            <Title level={3}>
              {intl.formatMessage({ id: 'kill_switch_configs_title' })}{' '}
              <Tooltip title={intl.formatMessage({ id: 'kill_switch_configs_tip' })}>
                <QuestionCircleOutlined rev={false} style={{ fontSize: '18px' }} />
              </Tooltip>
            </Title>
            {Object.entries(settings)
              .filter(([_, value]) => typeof value === 'boolean')
              .map(([key, value]) => (
                <Row key={key} style={{ marginTop: '13px' }} align="middle">
                  <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <PropertyTitle>
                      {intl.formatMessage({
                        id: `${snakeCase(key)}_label`
                      })}
                    </PropertyTitle>
                    <Tooltip
                      title={intl.formatMessage({
                        id: `${snakeCase(key)}_tip`
                      })}
                    >
                      <QuestionCircleOutlined rev={false} style={{ marginLeft: '10px' }} />
                    </Tooltip>
                  </Col>
                  <Radio.Group
                    value={value.toString()}
                    onChange={(e: any) => handleRadioChange(key, e)}
                    style={{ marginLeft: '20px' }}
                  >
                    <Radio value="true">
                      <T id="true" />
                    </Radio>
                    <Radio value="false">
                      <T id="false" />
                    </Radio>
                  </Radio.Group>

                  <Button
                    onClick={() => handleSave(settings, key)}
                    style={{ marginLeft: '10px' }}
                    loading={updateConfigLoading && updateConfigKey === key}
                  >
                    <T id="save" />
                  </Button>
                </Row>
              ))}
          </div>
        );
      case AVAILABLE_CONFIG_PAGES.SUPER_USERS:
        return (
          <div>
            <Title level={3}>
              {intl.formatMessage({ id: 'whitelisted_emails' })}{' '}
              <Tooltip title={intl.formatMessage({ id: 'super_users_configs_tip' })}>
                <QuestionCircleOutlined rev={false} style={{ fontSize: '18px' }} />
              </Tooltip>
            </Title>
            <List
              dataSource={settings.whiteListedEmails as string[]}
              style={{ marginBottom: '20px' }}
              renderItem={(email, index) => (
                <Row style={{ marginTop: '10px', alignItems: 'center' }} gutter={8}>
                  <Col span={8}>
                    <Input value={email} onChange={(e) => handleEmailChange(index, e.target.value)} type="email" />
                  </Col>
                  <Col span={4}>
                    <MinusCircleOutlined onClick={() => handleRemoveEmail(index)} rev={false} />
                  </Col>
                </Row>
              )}
            />
            <Button onClick={handleAddEmail}>
              <T id="add_row" />
            </Button>
            <Button
              onClick={() => handleSave(settings, 'whiteListedEmails')}
              type="primary"
              style={{ marginLeft: '10px' }}
              loading={updateConfigLoading}
            >
              <T id="save_emails" />
            </Button>
          </div>
        );
      case AVAILABLE_CONFIG_PAGES.STATE_CONFIGS:
        return (
          <div>
            <Title level={3}>
              {intl.formatMessage({ id: 'states_and_ages' })}{' '}
              <Tooltip title={intl.formatMessage({ id: 'state_configs_tip' })}>
                <QuestionCircleOutlined rev={false} style={{ fontSize: '18px' }} />
              </Tooltip>
            </Title>
            <List
              dataSource={Object.entries(settings.legalStatesAndAge as Record<string, number>)}
              style={{ marginBottom: '20px' }}
              renderItem={([state, age]) => (
                <Row style={{ marginTop: '10px', alignItems: 'center' }} gutter={8}>
                  <Col span={6}>
                    <Select
                      showSearch
                      filterOption={(input, option: any) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={state}
                      onChange={(newState) => handleLegalStateChange(newState, age)}
                      style={{ width: '100%' }}
                    >
                      {[state, ...availableStates].map((s) => (
                        <Option key={s} value={s}>
                          {s}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={age}
                      onChange={(val: number | null) => handleLegalStateChange(state, val)}
                      min={18}
                      max={99}
                    />
                  </Col>
                  <MinusCircleOutlined onClick={() => handleRemoveState(state)} rev={false} />
                </Row>
              )}
            />
            <Button onClick={handleAddState}>
              <T id="add_row" />
            </Button>
            <Button
              onClick={() => handleSave(settings, 'legalStatesAndAge')}
              type="primary"
              style={{ marginLeft: '10px' }}
              loading={updateConfigLoading}
            >
              <T id="save_legal_states" />
            </Button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Modal visible={modalVisible} onCancel={() => setModalVisible(false)} footer={<></>} width={700} centered>
        <ModalTitle>
          <T
            id="confirm_modal_title"
            values={{
              updateConfigKey:
                configSettings.key !== ''
                  ? intl.formatMessage({ id: `${snakeCase(configSettings.key)}_label` })
                  : configSettings.key
            }}
          />
        </ModalTitle>
        <ModalSubtitle>
          <T id="confirm_modal_subtitle" />
        </ModalSubtitle>
        <Input
          prefix={<LockOutlined className="site-form-item-icon" rev={undefined} />}
          type="number"
          placeholder={intl.formatMessage({ id: 'mfa_placeholder' })}
          style={{ width: '100%', marginTop: '30px' }}
          onChange={(e: any) => {
            setMfaCode(e.target.value);
          }}
        />
        <Button onClick={saveData} style={{ width: '100%', marginTop: '10px' }}>
          <T id="save" />
        </Button>
      </Modal>
      {getUi()}
    </>
  );
};

export default ConfigEditor;
