import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loading: false,
  userData: {},
  tokenData: {},
  qrImage: '',
  showMfa: false,
  isLoggedIn: false,
  showQr: false,
  loginError: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestLoginUser: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    successLoginUser(state, action) {
      state.loginError = null;
      state.showMfa = true;
      state.userData = action.payload;
      state.loading = false;
    },
    failureLoginUser(state) {
      state.loading = false;
      state.userData = {};
      state.tokenData = {};
      state.qrImage = '';
      state.showMfa = false;
      state.isLoggedIn = false;
      state.showQr = false;
      state.loginError = null;
    },
    requestConfirmMfa: {
      reducer: (state) => {
        state.loading = true;
      },
      prepare
    },
    successConfirmMFA(state, action) {
      state.loginError = null;
      state.tokenData = action.payload.tokenData;
      state.isLoggedIn = true;
      state.loading = false;
    },
    failureConfirmMFA(state, action) {
      state.loginError = get(action.payload, 'message', 'something_went_wrong');
      state.isLoggedIn = false;
      state.loading = false;
    },
    setQrImage(state, action) {
      state.qrImage = action.payload.image;
      state.userData = action.payload.user;
      state.showQr = true;
      state.loading = false;
    },
    setLoginData(state, action) {
      state.userData = action.payload.user;
      state.tokenData = action.payload.tokenData;

      if (Object.keys(action.payload.user).length === 0) {
        state.isLoggedIn = false;
      } else {
        state.isLoggedIn = true;
      }
    },
    resetState(state) {
      state.loading = false;
      state.userData = {};
      state.tokenData = {};
      state.showMfa = false;
      state.isLoggedIn = false;
      state.loginError = null;
    }
  }
});

export const {
  requestLoginUser,
  successLoginUser,
  failureLoginUser,
  requestConfirmMfa,
  successConfirmMFA,
  failureConfirmMFA,
  setQrImage,
  resetState,
  setLoginData
} = authSlice.actions;

export default authSlice.reducer;
