import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectUsersDomain = (state: any) => state.users || initialState;

export const selectUsersLoading = () => createSelector(selectUsersDomain, (substate) => get(substate, 'usersLoading'));

export const selectUsers = () => createSelector(selectUsersDomain, (substate) => get(substate, 'users'));

export const selectCardUsers = () => createSelector(selectUsersDomain, (substate) => get(substate, 'cardUsers'));
