/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import 'regenerator-runtime/runtime';
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import map from 'lodash-es/map';
import { withRouter, useLocation } from 'react-router';
import { Switch } from 'react-router-dom';
import { compose, AnyAction } from 'redux';
import { ThemeProvider } from 'styled-components';
import { Amplify, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { injectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';

import For from '@components/For';
import { colors } from '@themes/index';
import { HEADER_HEIGHT, MIN_SIDEBAR_WIDTH } from '@app/utils/constants';
import { awsConfig } from '@app/utils/config';
import { ProtectedRoute } from '@app/components';
import RootSaga from './saga';
import { selectIsLoggedIn } from '../AuthContainer/selectors';
import { setLoginData } from '../AuthContainer/reducer';
import { generateApiClient, getApiClient } from '@app/utils/apiUtils';
import GlobalStyle from '@app/global-styles';
import { routeConfig } from '@app/routeConfig';

const theme = {
  fg: colors.primary,
  bg: colors.secondaryText,
  sidebarWidth: MIN_SIDEBAR_WIDTH,
  headerHeight: HEADER_HEIGHT
};

type AppProps = {
  isLoggedIn: boolean;
  dispatchSetLoginData: (user: any, tokenData: any) => AnyAction;
};

Amplify.configure(awsConfig);
Auth.configure(awsConfig);
generateApiClient('betu');

export function App(props: AppProps) {
  const [tokenFetchingCompleted, setTokenFetchingCompleted] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setTokenFetchingCompleted(false);
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then((user) => {
        if (user.preferredMFA === 'NOMFA') {
          if (props.isLoggedIn && location.pathname !== '/login') {
            Auth.signOut();
            localStorage.clear();
            props.dispatchSetLoginData({}, {});
          }
          setTokenFetchingCompleted(true);
        } else {
          Auth.currentSession()
            .then((session: any) => {
              const accessToken = session.getAccessToken().jwtToken;

              getApiClient('betu').setHeader('authorization', `Bearer ${accessToken}`);
              props.dispatchSetLoginData(user, { accessToken: accessToken });
            })
            .catch(() => {
              props.dispatchSetLoginData({}, {});
            })
            .finally(() => setTokenFetchingCompleted(true));
        }
      })
      .catch(() => {
        props.dispatchSetLoginData({}, {});
        setTokenFetchingCompleted(true);
      });
  }, [props.isLoggedIn]);

  if (!tokenFetchingCompleted) {
    return <div>Loading...</div>; // or a spinner or some other placeholder
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout.Content>
        <For
          ParentComponent={(props) => <Switch {...props} />}
          of={map(Object.keys(routeConfig))}
          renderItem={(routeKey, index) => {
            const Component = routeConfig[routeKey].component;
            return (
              <ProtectedRoute
                isLoggedIn={props.isLoggedIn}
                exact={routeConfig[routeKey].exact!}
                key={index}
                path={routeConfig[routeKey].route!}
                render={(props) => {
                  const updatedProps = {
                    ...props,
                    ...routeConfig[routeKey].props
                  };
                  return <Component {...updatedProps} />;
                }}
              />
            );
          }}
        />
        <GlobalStyle />
      </Layout.Content>
    </ThemeProvider>
  );
}

export const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectIsLoggedIn()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchSetLoginData: (user: any, tokenData: any) => dispatch(setLoginData({ user, tokenData }))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect, injectSaga({ key: 'app', saga: RootSaga }))(App) as React.FC;
