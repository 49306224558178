import { call, put, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { AnyAction } from '@reduxjs/toolkit';
import { message } from 'antd';

import {
  failureLoginUser,
  requestConfirmMfa,
  requestLoginUser,
  setQrImage,
  successConfirmMFA,
  successLoginUser
} from './reducer';

import { getApiClient } from '@app/utils/apiUtils';

async function signInUser(data: any) {
  return Auth.signIn(data.email, data.password);
}

async function completeNewPassword(user: any, password: string) {
  return Auth.completeNewPassword(user, password);
}

async function signOut() {
  return Auth.signOut();
}

export function* loginUser(action: AnyAction): Generator<any, any, any> {
  try {
    const { email, password } = action.payload;

    let res;

    res = yield signInUser({ email, password });

    if (res.challengeName === 'NEW_PASSWORD_REQUIRED' || res.challengeName === null) {
      res = yield completeNewPassword(res, password);
      yield signOut();
      res = yield signInUser({ email, password });
    }

    if (res.preferredMFA === 'NOMFA') {
      const session = yield call([Auth, 'currentSession']);

      const accessToken = yield call([session, 'getAccessToken']);

      const token = accessToken.jwtToken;

      const apiRes = yield call([getApiClient(), 'post'], '/qrcode', { accessToken: token }, {});

      const image = apiRes.data.data;

      yield put(setQrImage({ image, user: res }));
    }

    if (res.challengeName === 'SOFTWARE_TOKEN_MFA') {
      yield put(successLoginUser(res));
    }
  } catch (err: any) {
    message.error(err.message);

    yield call([Auth, 'signOut']);
    yield put(failureLoginUser());
  }
}

export function* confirmMfa(action: AnyAction): Generator<any, any, any> {
  try {
    const { mfa, newSetup, user } = action.payload;

    if (newSetup) {
      const session = yield call([Auth, 'currentSession']);

      const accessToken = yield call([session, 'getAccessToken']);

      const token = accessToken.jwtToken;

      getApiClient('betu').setHeader('authorization', `Bearer ${token}`);

      yield call([getApiClient(), 'post'], '/verify-mfa', { accessToken: token, code: mfa }, {});

      yield put(successConfirmMFA({ tokenData: { accessToken: accessToken.jwtToken } }));
    } else {
      yield call([Auth, 'confirmSignIn'], user, mfa, 'SOFTWARE_TOKEN_MFA');

      yield call([Auth, 'currentAuthenticatedUser']);

      const session = yield call([Auth, 'currentSession']);

      const accessToken = yield call([session, 'getAccessToken']);

      const token = accessToken.jwtToken;

      getApiClient('betu').setHeader('authorization', `Bearer ${token}`);

      yield put(successConfirmMFA({ tokenData: { accessToken: token } }));
    }
  } catch (err: any) {
    message.error(err.message);

    yield call([Auth, 'signOut']);
    yield put(failureLoginUser());
  }
}

// Individual exports for testing
export default function* authContainerSaga() {
  yield takeLatest(requestLoginUser.toString(), loginUser);
  yield takeLatest(requestConfirmMfa.toString(), confirmMfa);
}

export const authContainerSagaArr = [
  takeLatest(requestLoginUser.toString(), loginUser),
  takeLatest(requestConfirmMfa.toString(), confirmMfa)
];
