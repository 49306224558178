import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Card, Table, Calendar } from 'antd';
import { injectIntl, useIntl } from 'react-intl';
import { AnyAction, compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { requestGetCardMetrics, requestGetMetrics, resetState } from './reducer';
import { selectCardMetrics, selectMetrics, selectMetricsLoading } from './selectors';
import { T } from '@app/components/T';
import { translationMessages } from '@app/i18n';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
`;

const MetricsTitle = styled.h1``;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const StatisticsCard = styled(Card)`
  width: 23%;
`;

const DataTable = styled(Table)`
  margin-top: 3.75rem;
`;

type MetricsContainerProps = {
  metrics: any;
  metricsLoading: boolean;
  cardMetrics: any;
  dispatchGetMetrics: (date: string) => AnyAction;
  dispatchResetState: () => AnyAction;
  dispatchGetCardMetrics: () => AnyAction;
};

const MetricsContainer = (props: MetricsContainerProps) => {
  const intl = useIntl();

  useEffect(() => {
    props.dispatchGetMetrics('');
    props.dispatchGetCardMetrics();
  }, []);

  const fetchMetrics = (date: any) => {
    props.dispatchGetMetrics(date.valueOf());
  };

  const getColumns = () => {
    const fixedColumns: any = [
      {
        title: 'Date',
        dataIndex: 'created_at',
        width: 120,
        fixed: 'left',
        render: (text: string) => moment.utc(text).tz('America/Chicago').format('DD/MM/YYYY'),
        filterDropdown: () => (
          <Calendar
            fullscreen={false}
            onChange={fetchMetrics}
            disabledDate={(date: any) => date && date.valueOf() > Date.now()}
          />
        )
      },
      {
        title: (
          <span>
            {translationMessages.en.betu_master_account_wallet_balance} <br />
            {translationMessages.en.unit_dollar}
          </span>
        ),
        dataIndex: 'betuWalletBalance',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.num_deposits_pending} <br />
            {translationMessages.en.unit_per_day}
          </span>
        ),
        dataIndex: 'dailyDepositInitiationCount',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.total_outstanding_amount} <br />
            {translationMessages.en.unit_dollar}
          </span>
        ),
        dataIndex: 'totalPendingDepositsAmount',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.num_deposits_failed} <br />
            {translationMessages.en.unit_per_day}
          </span>
        ),
        dataIndex: 'dailyFailedDepositCount',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.sum_deposits_failed} <br />
            {translationMessages.en.unit_per_day}
          </span>
        ),
        dataIndex: 'dailyFailedDepositAmount',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.total_num_signups} <br />
            {translationMessages.en.unit_till_date}
          </span>
        ),
        dataIndex: 'totalSignUpCountAllTime',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.num_wagers_placed} <br />
            {translationMessages.en.unit_per_day}
          </span>
        ),
        dataIndex: 'dailyWagerPlacedCount',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.total_num_wagers_placed} <br />
            {translationMessages.en.unit_till_date}
          </span>
        ),
        dataIndex: 'totalWagerPlacedCountAllTime',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.num_pending_wagers} <br />
            {translationMessages.en.unit_till_date}
          </span>
        ),
        dataIndex: 'totalPendingWagersCountAllTime',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.num_users_without_wagers} <br />
            {translationMessages.en.unit_till_date}
          </span>
        ),
        dataIndex: 'totalUsersWithoutWagersAllTime',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: <span>{translationMessages.en.num_wagers_with_games}</span>,
        dataIndex: 'dailyGamesWithWagersCount',
        render: (text: string) => Number(text).toFixed(2)
      },
      {
        title: (
          <span>
            {translationMessages.en.num_wagers_with_games_all_time} <br />
            {translationMessages.en.unit_all_time}
          </span>
        ),
        dataIndex: 'totalGamesWithWagersCountAllTime',
        render: (text: string) => Number(text).toFixed(2)
      }
    ];

    return fixedColumns;
  };

  return (
    <PageContainer>
      <MetricsTitle>
        <T id="metrics" />
      </MetricsTitle>
      <CardContainer>
        <StatisticsCard title={intl.formatMessage({ id: 'betu_balance_card_title' })}>
          <p>{Number(props.cardMetrics.betuBalance ? props.cardMetrics.betuBalance : 0).toFixed(2)}</p>
        </StatisticsCard>
        <StatisticsCard title={intl.formatMessage({ id: 'total_user_profiles' })}>
          <p>{Number(props.cardMetrics.totalUsers ? props.cardMetrics.totalUsers : 0).toFixed(2)}</p>
        </StatisticsCard>
        <StatisticsCard title={intl.formatMessage({ id: 'sum_of_wager_amount' })}>
          <p>{Number(props.cardMetrics.wagerAmount ? props.cardMetrics.wagerAmount : 0).toFixed(2)}</p>
        </StatisticsCard>
        <StatisticsCard title={intl.formatMessage({ id: 'sum_of_virtual_amount_upfronted' })}>
          <p>{Number(props.cardMetrics.digitalAmount ? props.cardMetrics.digitalAmount : 0).toFixed(2)}</p>
        </StatisticsCard>
      </CardContainer>
      <DataTable
        bordered={true}
        virtual
        columns={getColumns().map((col: any) => ({ ...col, onCell: undefined }))}
        scroll={{ x: 2000, y: 300 }}
        rowKey="id"
        dataSource={props.metrics}
        pagination={false}
        loading={props.metricsLoading}
      />
    </PageContainer>
  );
};

MetricsContainer.propTypes = {
  metrics: PropTypes.array,
  metricsLoading: PropTypes.bool,
  cardMetrics: PropTypes.object,
  dispatchGetMetrics: PropTypes.func,
  dispatchResetState: PropTypes.func,
  dispatchGetCardMetrics: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  metrics: selectMetrics(),
  metricsLoading: selectMetricsLoading(),
  cardMetrics: selectCardMetrics()
});

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchGetMetrics: (date: string) => dispatch(requestGetMetrics({ date })),
    dispatchGetCardMetrics: () => dispatch(requestGetCardMetrics({})),
    dispatchResetState: () => dispatch(resetState())
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(MetricsContainer);
